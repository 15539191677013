import React from "react";
import Pill from "./Pill";

import DocumentIcon from "../assets/icons/document.svg";
import DocumentIconWhite from "../assets/icons/document_white.svg";

const DownloadWordButton = ({ url, style = {} }) => (
  <Pill
    label="Download in .docx"
    linkTo={url}
    external
    icon={{
      url: DocumentIcon,
      activeUrl: DocumentIconWhite,
      width: "21px",
      height: "26px",
    }}
    style={style}
  />
);

export default DownloadWordButton;
