/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import { Link } from "gatsby";

const Pill = ({
  label,
  ariaLabel,
  onClick = () => {},
  active,
  style,
  linkTo,
  external,
  icon,
  disabled,
  state,
  type,
}) => {
  let linkProps = {};

  if (linkTo) {
    if (external) {
      linkProps = {
        as: "a",
        href: linkTo,
      };
    } else {
      linkProps = {
        as: Link,
        to: linkTo,
      };
    }
  }

  return (
    <div
      sx={{
        border: "1px solid",
        borderColor: "text",
        borderRadius: "30px",
        width: "max-content",
        ...style,
      }}
    >
      <Button
        sx={{
          border: "2px solid transparent",
          borderRadius: "30px",
          height: "50px",
          padding: "0 30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bg: active ? "text" : "background",
          fontFamily: "body",
          fontSize: 0,
          lineHeight: 0,
          color: active ? "background" : "text",
          cursor: "pointer",
          whiteSpace: "nowrap",
          transitionProperty: "background, color, opacity",
          transitionDuration: "300ms",
          ":hover": {
            bg: [null, null, "text"],
            color: [null, null, "background"],
          },
          "&:hover > .icon": {
            backgroundImage: icon && [null, null, `url(${icon.activeUrl})`],
          },
          ":focus": {
            borderColor: "text",
          },
          "&:disabled": {
            cursor: "not-allowed",
            bg: active ? "text" : "background",
            color: active ? "background" : "text",
            "> .icon": {
              backgroundImage: icon && `url(${icon.url})`,
            },
          },
        }}
        onClick={onClick}
        aria-label={`${ariaLabel || label}${active ? ", selected" : ""}`}
        disabled={disabled}
        state={state}
        type={type}
        {...linkProps}
      >
        {icon && (
          <span
            className="icon"
            sx={{
              background: `url(${icon.url}) no-repeat 50% 50% / contain`,
              width: icon.width,
              height: icon.height,
              marginRight: "0.5rem",
              transition: "background 300ms",
            }}
          />
        )}
        <span
          sx={{
            "@media screen and (-ms-high-contrast: active)": {
              textDecoration: active ? "underline" : "none",
            },
          }}
        >
          {label}
        </span>
      </Button>
    </div>
  );
};

export default Pill;
