import React, { Fragment, useState, useRef } from "react";
import Pill from "./Pill";

import LinkIcon from "../assets/icons/link.svg";
import LinkIconWhite from "../assets/icons/link_white.svg";

const CopyURLButton = ({ url, style = {} }) => {
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const inputRef = useRef(null);
  const showCopySuccessTimeoutId = useRef(null);

  const onClick = () => {
    clearTimeout(showCopySuccessTimeoutId.current);

    // The button will be the active (focused) element when it has just been clicked.
    const button = document.activeElement;

    inputRef.current.disabled = false;
    inputRef.current.focus();
    inputRef.current.select();
    inputRef.current.disabled = true;

    document.execCommand("copy");

    // For screen reader users, the button will lose focus after focusing the input field,
    // so we need to focus it again.
    button.focus();

    setShowCopySuccess(true);
    showCopySuccessTimeoutId.current = setTimeout(
      () => setShowCopySuccess(false),
      2000
    );
  };

  const label = showCopySuccess ? "Copied!" : "Copy URL";

  return (
    <Fragment>
      <input
        ref={inputRef}
        type="text"
        style={{
          position: "absolute",
          opacity: 0,
          pointerEvents: "none",
        }}
        disabled
        aria-hidden
        value={url}
      />
      <Pill
        label={label}
        ariaLabel={label}
        onClick={onClick}
        icon={{
          url: LinkIcon,
          activeUrl: LinkIconWhite,
          width: "22px",
          height: "22px",
        }}
        style={style}
      />
    </Fragment>
  );
};

export default CopyURLButton;
