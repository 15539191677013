/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { Fragment } from "react";
import Layout from "../components/Layout";
import Separator from "../components/Separator";
import RichText from "../components/RichText";
import HolisticSkillCardGrid from "../components/HolisticSkillCardGrid";
import PrintButton from "../components/PrintButton";
import DownloadWordButton from "../components/DownloadWordButton";
import EmailButton from "../components/EmailButton";
import CopyURLButton from "../components/CopyURLButton";
import { DESKTOP_ARTICLE_MAX_WIDTH } from "../utils/Constants";

import { LifeSkills } from "../assets/images/brailleheaders";

const HolisticSkill = ({
  pageContext: { holisticSkill, goalsInHolisticSkill, otherHolisticSkills },
  location,
}) => {
  const GoalList = ({ title, goals }) => {
    const parentGoals = goals.reduce((acc, goal) => {
      if (goal.parent) {
        const parentIndex = acc.findIndex(g => g.goal.uid === goal.parent.uid);

        if (parentIndex === -1) {
          acc.push({
            goal: goal.parent,
            children: [goal],
          });
        } else {
          acc[parentIndex].children.push(goal);
        }
      } else {
        acc.push({
          goal,
          children: [],
        });
      }

      return acc;
    }, []);

    return (
      <Fragment>
        <Themed.h2
          sx={{ variant: "text.h4", color: "lbbRed", fontSize: "26px" }}
        >
          {title}
        </Themed.h2>
        <ul>
          {parentGoals
            .sort((a, b) => a.goal.number - b.goal.number)
            .map(({ goal, children }) => (
              <Themed.li key={goal.uid}>
                {goal.text}
                {children.length > 0 && (
                  <ul>
                    {children
                      .sort((a, b) => a.number - b.number)
                      .map(child => (
                        <Themed.li key={child.uid}>{child.text}</Themed.li>
                      ))}
                  </ul>
                )}
              </Themed.li>
            ))}
        </ul>
      </Fragment>
    );
  };

  const sections = [
    {
      title: "Academic skills",
      goals: goalsInHolisticSkill.filter(goal => goal.type === "Academic"),
    },
    {
      title: "Visual impairment skills",
      goals: goalsInHolisticSkill.filter(goal => goal.type === "VI"),
    },
  ];

  return (
    <Layout
      title={holisticSkill.name}
      breadcrumbsMap={{
        [holisticSkill.uid]: holisticSkill.name,
      }}
      brailleTextImage={LifeSkills}
    >
      <div
        sx={{
          maxWidth: [null, null, `${DESKTOP_ARTICLE_MAX_WIDTH}px`],
        }}
      >
        <Themed.h1>{holisticSkill.name}</Themed.h1>
        <RichText render={holisticSkill.body} />
        {sections.map(({ title, goals }, index) => (
          <div
            key={title}
            sx={{
              flex: 1,
              marginTop: "2rem",
              marginRight: [null, null, index === 0 ? "4rem" : 0],
            }}
          >
            <GoalList title={title} goals={goals} />
          </div>
        ))}
        <div
          sx={{
            "@media print": { display: "none" },
          }}
        >
          <Themed.h3 sx={{ margin: "2rem 0" }}>Download & print</Themed.h3>
          <ul
            sx={{
              listStyleType: "none",
              padding: 0,
              display: "flex",
              flexDirection: ["column", "row"],
              "> *": {
                marginRight: [0, "1rem"],
                marginBottom: ["1rem"],
              },
              "@media screen and (max-width: 1024px) and (min-width: 768px)": {
                flexWrap: "wrap",
              },
            }}
          >
            <li>
              <PrintButton />
            </li>
            {holisticSkill.wordDocument && (
              <li>
                <DownloadWordButton url={holisticSkill.wordDocument} />
              </li>
            )}
            <li>
              <EmailButton emailBody={location.href} />
            </li>
            <li>
              <CopyURLButton url={location.href} />
            </li>
          </ul>
        </div>
      </div>
      <Separator />
      <div sx={{ "@media print": { display: "none" } }}>
        <HolisticSkillCardGrid holisticSkills={otherHolisticSkills} />
      </div>
    </Layout>
  );
};

export default HolisticSkill;
