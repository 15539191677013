import React from "react";
import Pill from "./Pill";

import PrintIcon from "../assets/icons/print.svg";
import PrintIconWhite from "../assets/icons/print_white.svg";

const PrintButton = ({ style = {} }) => (
  <Pill
    label="Print"
    onClick={() => window.print()}
    icon={{
      url: PrintIcon,
      activeUrl: PrintIconWhite,
      width: "23px",
      height: "24px",
    }}
    style={style}
  />
);

export default PrintButton;
