import React from "react";
import Pill from "./Pill";

import EmailIcon from "../assets/icons/email.svg";
import EmailIconWhite from "../assets/icons/email_white.svg";

const EmailButton = ({ emailBody, style = {} }) => (
  <Pill
    label="Share via email"
    linkTo={`mailto:?body=${emailBody}`}
    external
    icon={{
      url: EmailIcon,
      activeUrl: EmailIconWhite,
      width: "31px",
      height: "22px",
    }}
    style={style}
  />
);

export default EmailButton;
